$(document).ready(function () {
  // current year
  // document.getElementById("year").innerHTML = new Date().getFullYear();
  // $(".loader-api").delay(300).fadeOut(600);
  var wido = $(window).width();
  setTimeout(function () {
    $(".form-group-wrap-tel-outer .pr-form-control .focus-border-ani").after(
      '<label class="form-label h6" for="mobile_no">My Number is</label>'
    );
    $(".input-effect input, .input-effect textarea").on('focusout', function () {
      if ($(this).val() != "") {
        $(this).addClass("has-content");
        $(this).parents(".pr-form-control").addClass("active");
      } else {
        $(this).removeClass("has-content");
        $(this).removeClass(".pr-form-control").addClass("active");
      }
    });

    // $(".comman-header-wrap").removeClass("comman-header-active-class");
    $(".comman-header .mega-menu.mega-menu-open").click(function () {
      // alert(1);
      $(".comman-top-menu").addClass("active-class");
      // $(".comman-header-wrap").toggleClass("comman-header-active-class");
    });
    $("#btn-service-nav").click(function () {
      $(".comman-top-menu").removeClass("active-class");
    });
    $(".comman-top-menu .mega-menu.mega-menu-close").click(function () {
      // alert(2);
      $(".comman-top-menu").removeClass("active-class");
      // $(".comman-header-wrap").toggleClass("comman-header-active-class");
    });
    $('[data-toggle="tooltip"]').tooltip();
    $(".comman-top-menu .btn-line, .comman-top-menu .btn-line-b").hover(
      function () {
        $(".comman-top-menu .btn-line, .comman-top-menu .btn-line-b")
          .not($(this))
          .css("opacity", 0.15);
      },
      function () {
        $(".comman-top-menu .btn-line, .comman-top-menu .btn-line-b")
          .not($(this))
          .css("opacity", 1);
      }
    );

    $(
      ".comman-footer .bottom-menu-bottom-left li a, .comman-footer .bottom-menu-bottom-right li a"
    ).hover(
      function () {
        $(
          ".comman-footer .bottom-menu-bottom-left li a, .comman-footer .bottom-menu-bottom-right li a"
        )
          .not($(this))
          .css("opacity", 0.15);
        $(
          ".comman-footer .bottom-menu-bottom-left li p.copy-para, .comman-footer .bottom-menu-bottom-right li p.copy-para"
        ).css("opacity", 0.15);
      },
      function () {
        $(
          ".comman-footer .bottom-menu-bottom-left li a, .comman-footer .bottom-menu-bottom-right li a"
        )
          .not($(this))
          .css("opacity", 1);
        $(
          ".comman-footer .bottom-menu-bottom-left li p.copy-para, .comman-footer .bottom-menu-bottom-right li p.copy-para"
        ).css("opacity", 1);
      }
    );

    // for isChrome
    var isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      $(".pr-form-control textarea.input-control-wrap ~ .focus-border").css(
        "bottom",
        "6px"
      );
    }
    // disable animation for mobile
    if ($(window).width() < 1199) {
      $("[data-aos]").addClass("aos-init aos-animate");
    }
  }, 1000);

  $(".comman-top-menu .comman-header-inner .container-fluid.mw-1470 ul.list-inline.comman-header-menu-ul.comman-header-menu-right").click(function () {
    $("section.comman-top-menu.active-class").removeClass("active-class");
  });
});
